import React from 'react';
import {
  DontDo,
  Link,
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({}) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Color"
      designCode
      subnav="guidelines">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Accessibility"
        tierThree="Color"
      />

      <Section title="Accessible Color Rules">
        <Paragraph>
          Adhere to the following criteria around using color in interfaces,
          provided by the Web Content Accessibility Guidelines (WCAG):
        </Paragraph>
        <SectionSubhead>Sensory Characteristics</SectionSubhead>
        <Paragraph>
          <Link href="https://www.w3.org/WAI/WCAG21/Understanding/sensory-characteristics.html">
            According to WCAG 1.3.3 (Sensory Characteristics)
          </Link>
          , instructions for using or understanding content on the page cannot
          rely on color, size, visual orientation, shape, or sound. For example,
          instead of telling a user to "press the blue button on the right to
          continue," tell them to "press the 'Submit' button to continue."
        </Paragraph>

        <SectionSubhead>Use of Color</SectionSubhead>
        <Paragraph>
          <Link href="https://www.w3.org/WAI/WCAG21/Understanding/use-of-color.html">
            WCAG 1.4.1 (Use of Color)
          </Link>
          requires that color cannot be the only visual means of conveying
          information, indicating an action, prompting a response or
          distinguishing a visual element.
        </Paragraph>
        <DontDo
          dontText="use color alone to indicate error states."
          doText="use icons with color to show an error."
          imgFilename="a11ycolors-use"
        />

        <SectionSubhead>Text Contrast</SectionSubhead>
        <Paragraph>
          <Link href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html">
            WCAG 1.4.3 (Contrast Minimum)
          </Link>{' '}
          sets a minimum color contrast ratio of <strong>3:1</strong> for bold
          text at least 18.5px or non-bold text 24px and above. For all other
          cases, minimum color contrast ratio should be <strong>4.5:1</strong>.
          Use a tool like the{' '}
          <Link href="https://www.figma.com/community/plugin/732603254453395948/Stark">
            Figma Stark plugin
          </Link>{' '}
          to check color contrast of custom colors. If your project uses
          Storybook, test the built interface using the Accessibility add-on
          panel to find any violations.
        </Paragraph>
        <DontDo
          dontText="use text colors that don't meet the contrast requirements."
          doText="use Uniform colors whenever possible."
          imgFilename="a11ycolors-textcontrast"
        />

        <SectionSubhead>Non-text Contrast</SectionSubhead>
        <Paragraph>
          The other color contrast rule,{' '}
          <Link href="https://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast.html">
            WCAG 1.4.11 (Contrast)
          </Link>
          , requires that non-text colors that appear adjacent to each other in
          interactive elements and meaningful graphics have a{' '}
          <strong>3:1</strong> color contrast ratio. This rule applies primarily
          to fills, borders and outlines on interactive elements like buttons,
          or larger graphics that use color to show different fields or values.
          Most of these cases are handled within Uniform components, but you may
          need to test color ratios using the{' '}
          <Link href="https://www.figma.com/community/plugin/732603254453395948/Stark">
            Figma Stark plugin
          </Link>{' '}
          when designing custom graphics or data visualizations.
        </Paragraph>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
